import { OrderStatusCodes } from '@services/orders-service/orders-service';
import {
    appConfigurationProd,
    CountriesByLocale,
} from './app-configuration/app-configuration';
import { ConnectedServicesCountrySelector } from '@models/connected-vehicle';
import RandomNumberUtil from '@utils/random-number-util/random-number-util';

export const DSL_BASE_URL = process.env.REACT_APP_DSL_BASE_URL;

export const DSL_API_PATH = {
    VEHICLE_IMAGE_SERVICE: '/vehicleimage/api/v2/vehicles/image/full',
    PROFILE_WITH_VEHICLES: '/owner/api/v3/profile',
    ADD_VEHICLE: '/fs/api/v3/vehicles',
    AGGREGATE_POINTS: '/fs/api/v3/loyalty/aggregatedPoints',
    TERMS_AND_CONDITIONS: '/climes/api/v2/terms',
    TERMS_AND_CONDITIONS_CONSENT: '/owner/api/v2/consents',
    TOKEN_SWAP: '/fs/api/v2/profile/token/swap',
    UPDATE_USER_INFO: '/fs/api/v3/profile',
    UPDATE_USER_ADDRESS: '/owner/api/v3/profile',
    UPDATE_NA_PRIVACY_PREFERENCE: '/fs/api/v2/profile/privacy-preferences',
    UPDATE_PRIVACY_PREFERENCE_V3: '/fs/api/v3/profile/privacy-preferences',
    UPDATE_IMG_PRIVACY_PREFERENCE: '/fs/api/v3/privacy-preference',
    UPDATE_ECP_PREFERENCE:
        '/scagif/api/v2/marketing/profile-privacy-preference',
    UPDATE_EMAIL: '/scagif/api/v2/marketing/profile-email',
    GET_ECP_PREFERENCES: '/scagif/api/v2/marketing/profile-ecp',
    UNITS_OF_MEASUREMENT: '/fs/api/v2/profile/unit',
    EU_PREFERENCES: '/fs/api/v2/profile/eu-preferences',
    CONSUMER_ID: '/owner/api/v2/consumer-id',
    DELETE_ACCOUNT: '/fs/api/v2/profile/delete-account',
    RESERVATIONS: '/owner/api/v2/reservations',
    CURRENT_BOOKINGS: '/owner/api/v2/current-bookings',
    MFA_OPTIONS: '/fs/api/v2/profile/mfa',
    CONNECTED_VEHICLE: '/owner/api/v3/vehicle/status',
    EYF_CHECKLIST: '/owner/api/v3/handover/checklist',
    SUBSCRIPTIONS: '/consumer/api/cat/v2/subscriptions',
    PROGNOSTICS: '/fs/api/v3/vehicles/prognostics',
    RECALLS: '/gcamp/api/v5/recalls',
    MNV_OFFER: '/consumer/api/cat/v1/mnv',
    PREFERRED_DEALER: '/nasapi/api/v1/dealer/dealers',
    PROFILE_LITE: '/fs/api/v2/profile/lite',
    BING_DEALERS: '/bing/api/v3/dealers',
    CUSTOMER_ORDER_TRACKING: '/nasapi/api/v1/customer-order/tracking-data',
    CUSTOMER_ORDER_TRACKING_CAT: '/nasapi/api/v2/customer-order/tracking-data',
    WINDOW_STICKER: '/nasapi/api/v1/inventory/windowSticker',
    ADD_DELETED_VEHICLE: '/consumer/api/cat/v1/vehicle/addDeletedVehicle',
    GET_DELETED_VEHICLES: '/consumer/api/cat/v1/vehicle/getDeletedVehicles',
    OSB_DEALER_DETAILS: '/osb/api/public/v1/:locale/dealers',
    OMS_ORDER_HISTORY: '/oms/api/v1/order-history',
    OMS_ORDER_DETAILS: '/oms/api/v1/order-details',
    OMS_ORDER_DETAILS_AUTH: '/oms/api/v1/order-details-auth',
    OMS_RETURN_LABEL: '/oms/api/v1/order-return/label',
    OMS_PACKING_SLIP: '/oms/api/v1/order-return/packing-slip',
    OMS_CREATE_RETURN: '/oms/api/v1/order-return/create',
    FEATURE_GUIDE: '/featureguide/api/v3/integrations/generateGuide',
    PREFERRED_DEALER_CONSENT: '/fs/api/v2/privacy',
};

export const GOOGLE_MAP_SCRIPT = `https://maps.googleapis.com/maps/api/js?&client=gme-fordmotorcompany2&libraries=places`;
export const EU_BING_KEY =
    'Al1EdZ_aW5T6XNlr-BJxCw1l4KaA0tmXFI_eTl1RITyYptWUS0qit_MprtcG7w2F';
export const EU_BING_BASE_URL =
    'https://spatial.virtualearth.net/REST/v1/data/1652026ff3b247cd9d1f4cc12b9a080b/FordEuropeDealers_Transition/Dealer';
export const EU_POLYGON =
    '-7.88818359375+63.27318217465046%2C-10.26123046875+55.02802211299252%2C-5.60302734375+53.61857936489517%2C-6.92138671875+50.819818262156545%2C-6.43798828125+47.398349200359256%2C-0.24169921875+49.32512199104001%2C3.18603515625+52.02545860348814%2C0.24169921875+60.930432202923335%2C-1.07666015625+62.81501879409271%2C-7.88818359375+63.27318217465046';
export const CHARGER_ADAPTER_STATUS_QUERY_PARAM =
    '&keyName=chargeradapterstatus';

export const SESSION_KEY_API_CACHE = 'apiCache';

export const PREFERRED_DEALER_STORAGE_KEY = 'selectedVinPreferredDealer';

export const VEHICLE_CARD_STORAGE_KEY = 'USER_VIN';

export const VEHICLE_CARD_SESSION_STORAGE_KEY = 'selectedVin';

export const CVOT_AUTHENTICATED_ORDER_STORAGE_KEY = 'cvotAuthenticatedOrder';

export const PREFERRED_DEALER_CONSENT_KEY =
    'Customer Directed Sharing for third party service or product';

export const BRAND = {
    ford: {
        LONG_NAME: 'ford',
        SHORT_NAME: 'F',
    },
    lincoln: {
        LONG_NAME: 'lincoln',
        SHORT_NAME: 'L',
    },
};

export const POSSIBLE_FUEL_TYPE_VALUES = [
    'E',
    'Electric',
    'G',
    'Gasoline',
    'H',
    'Hybrid',
];

export const SERVICE_LOCATION_STEP_KEYS = {
    BACK_LABEL: 'backLabel',
    SERVICE_LOCATION_TITLE: 'serviceLocationTitle',
    SERVICE_LOCATION_TEXT: 'serviceLocationText',
    PICKUP_SERVICE_LOCATION_TITLE: 'pickUpserviceLocationTitle',
    PICKUP_SERVICE_LOCATION_TEXT: 'pickUpserviceLocationText',
    STREET_NAME_LABEL: 'streetNameLabel',
    STREET_NAME_ERROR: 'streetNameError',
    STREET_NAME_MANDATORY: 'isStreetNameMandatory',
    HOUSE_NUMBER_LABEL: 'houseNumberLabel',
    HOUSE_NUMBER_ERROR: 'houseNumberError',
    HOUSE_NUMBER_MANDATORY: 'isHouseNumberMandatory',
    POSTCODE_LABEL: 'postcodeLabel',
    POSTCODE_ERROR: 'postcodeError',
    POSTCODE_MANDATORY: 'isPostCodeMandatory',
    CITY_LABEL: 'cityLabel',
    CITY_ERROR: 'cityError',
    CITY_MANDATORY: 'isCityMandatory',
    PARKING_TYPE_LABEL: 'parkingTypeLabel',
    PARKING_TYPE_ERROR: 'parkingTypeError',
    PARKING_TYPE_MANDATORY: 'isParkingTypeMandatory',
    PARKING_TYPE_OPTIONS: 'parkingTypeOptions',
    CONTINUE_BUTTON: 'continueButtonLabel',
    PLEASE_NOTE_TEXT: 'pleaseNoteText',
    POSTCODE_LENGTH: 'postcodeLength',
    OTHER_OPTIONS_TITLE: 'otherOptionsTitle',
    POSTCODE_AUTOCOMPLETE_SEARCH_STRING_LENGTH:
        'postcodeAutocompleteSearchStringLength',
    SEARCH_HINT_LABEL: 'searchHintLabel',
    SEARCH_PREDICTION_TYPE: 'postal_code',
    PICKUP_AND_DELIVERY_DISCLAIMER_TEXT: 'pickupAndDeliveryDisclaimerText',
    LOCATION_BOOKING_TYPE_MOBILE_SERVICE: 'MobileService',
    LOCATION_BOOKING_TYPE_VEHICLE_PICKUP: 'VehiclePickUp',
};
export const GDPR_COOKIE = 'guxfoe-cookiedisclaimer';
export const COOKIE_CONFIGURATION = 'cookie-configuration';

export const DEFAULT_GDPR_COOKIE_VALUE =
    'strictly necessary:yes|performance:yes|functionality:yes|targeting:no';

export const REGIONAL_LOCALES = Array.from(
    new Set(
        appConfigurationProd.countryConfigurations
            .flatMap(
                (countryConfiguration) =>
                    countryConfiguration.regionConfigurations
            )
            .map((config) => config.languageRegionCode)
    )
);

export const PHONE_NUMBER_COUNTRIES: string[] = ['th', 'za', 'ph', 'vn'];

export const EU_COUNTRIES = [
    'se',
    'gb',
    'ch',
    'de',
    'fr',
    'it',
    'es',
    'no',
    'nl',
    'fi',
    'ie',
    'at',
    'be',
    'lu',
    'pt',
    'dk',
    'cz',
    'gr',
    'hu',
    'pl',
    'ro',
    'ru',
    'tr',
];

export const EU_PREFERRED_DEALER_ID_PREFIXES = [
    { country: 'gb', prefix: 'UB' },
    { country: 'ie', prefix: 'IG' },
    { country: 'de', prefix: 'GK' },
    { country: 'fr', prefix: 'FF' },
    { country: 'es', prefix: 'SK' },
    { country: 'it', prefix: 'IJ' },
    { country: 'lu', prefix: 'BD' },
    { country: 'be', prefix: 'BD' },
    { country: 'pt', prefix: 'PH' },
    { country: 'ro', prefix: 'RB' },
    { country: 'hu', prefix: 'HG' },
    { country: 'no', prefix: 'NQ' },
    { country: 'se', prefix: 'ST' },
    { country: 'dk', prefix: 'DB' },
    { country: 'nl', prefix: 'NB' },
    { country: 'fi', prefix: 'FD' },
    { country: 'cz', prefix: 'CZ' },
    { country: 'gr', prefix: 'GF' },
    { country: 'pl', prefix: 'PG' },
    { country: 'at', prefix: 'AK' },
    { country: 'ch', prefix: 'SU' },
];

export const IMG_COUNTRIES = ['vn', 'au', 'nz', 'ph', 'th', 'za'];

export const SA_COUNTRIES = ['ar', 'br', 've', 'pe', 'co', 'cl'];

export const NA_COUNTRIES = ['us', 'ca'];
export const MX_COUNTRY = ['mx'];

export const PERSON_ICON_COUNTRIES: string[] = [
    ...IMG_COUNTRIES,
    ...EU_COUNTRIES,
    ...SA_COUNTRIES,
    ...MX_COUNTRY,
];

export const SYNDICATED_HEADER_COUNTRIES = [
    'mx',
    ...NA_COUNTRIES,
    ...SA_COUNTRIES,
    ...IMG_COUNTRIES,
];

export const CSP_SCRIPT_SOURCES = [
    '*.jquery.com',
    '*.googletagmanager.com',
    '*.gstatic.com',
    '*.google-analytics.com',
    '*.adobedtm.com',
    '*.evidon.com',
    '*.cookielaw.org',
    '*.liveperson.net',
    '*.liveperson.com',
    '*.lpsnmedia.net',
    '*.iperceptions.com',
    '*.onetrust.com',
    '*.mpeasylink.com',
    '*.go-mpulse.net',
    '*.mouseflow.com',
    '*.amazonaws.com',
    '*.amitirefinder.com',
    '*.brightcove.net',
    '*.zencdn.net',
    '*.omtrdc.net',
    '*.youtube.com',
    '*.virtualearth.net',
    '*.googleapis.com',
    '*.gstatic.com',
    '*.marketingassociates.com',
    '*.bing.com',
    '*.facebook.net',
    '*.kampyle.com',
    '*.medallia.com',
    '*.googleadservices.com',
    '*.adnxs.com',
    '*.pinimg.com',
    '*.googlesyndication.com',
    '*.convertlanguage.com',
    '*.cardinalcommerce.com',
    '*.bluesnap.com',
    '*.contentsquare.net',
    '*.hotjar.com',
    '*.doubleclick.net',
    '*.stripe.com',
    '*.google.com',
    '*.amplitude.com',
];

export const CSP_DOMAINS = [
    '*.ford.com',
    '*.lincoln.com',
    '*.ford.ca',
    '*.lincolncanada.com',
    '*.ford.co.uk',
    '*.ford.ch',
    '*.ford.de',
    '*.ford.fr',
    '*.ford.it',
    '*.ford.es',
    '*.ford.no',
    '*.ford.nl',
    '*.ford.fi',
    '*.ford.com.br',
    '*.ford.mx',
    '*.lincoln.mx',
    '*.ford.com.ar',
    '*.ford.pl',
    '*.ford.pt',
    '*.ford.ie',
    '*.ford.dk',
    '*.ford.hu',
    '*.ford.ro',
    '*.ford.be',
    '*.ford.lu',
    '*.ford.at',
    '*.ford.gr',
    '*.ford.cz',
    '*.ford.se',
    '*.ford.com.au',
    '*.ford.co.nz',
    '*.ford.co.za',
    '*.ford.com.vn',
    '*.ford.com.ph',
    '*.ford.co.th',
    '*.ford.cl',
    '*.ford.com.co',
    '*.ford.pe',
    '*.ford.com.ve',
];

export const KEYBOARD_KEYS = {
    ESC: 'Escape',
    TAB: 'Tab',
    ENTER: 'Enter',
    END: 'End',
    HOME: 'Home',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_LEFT: 'ArrowLeft',
};

export const LIVE_CHAT_DIV_IDS: { [key: string]: string } = {
    'en-gb': 'LP_DIV_1635836056975',
    'de-de': 'LP_DIV_1635860279397',
    'fr-fr': 'LP_DIV_1635166089005',
    'it-it': 'LP_DIV_1635962710531',
    'nl-nl': 'LP_DIV_1665987404713',
    'de-at': 'LP_DIV_1678094161465',
    'da-dk': 'LP_DIV_1676629277418',
    'no-no': 'LP_DIV_1674132498454',
};

export const ORDER_STATUS_CODES: OrderStatusCodes = {
    us: {
        inProduction: [
            'BWKA',
            'GRTA',
            'OSEQ',
            'STRT',
            'PROD',
            'VASC',
            'VSHP',
            'VDOS',
        ],
        shipped: ['VITR', 'VEHS'],
        purchaseCompleted: ['VAFD'],
        delivered: ['VPHD'],
    },
    ca: {
        inProduction: [
            'BWKA',
            'GRTA',
            'OSEQ',
            'STRT',
            'PROD',
            'VASC',
            'VSHP',
            'VDOS',
        ],
        shipped: ['VITR', 'VEHS'],
        purchaseCompleted: ['VAFD'],
        delivered: ['VPHD'],
    },
    eu: {
        inProduction: ['GRTA', 'STRT', 'PROD', 'VASC', 'VSHP', 'VDOS', 'IPRL'],
        shipped: [
            'VAFD',
            'VITR',
            'VPRT',
            'VHLG',
            'VETL',
            'VMOD',
            'VCRB',
            'VAND',
            'VCMP',
            'VEHS',
        ],
        purchaseCompleted: [],
        delivered: ['VPHD'],
    },
};

export const CANCELLED_ORDER_STATUSES: Array<string> = [
    'OCAN',
    'CANCELLED',
    'RESERVATION_CANCELLED',
    'ORDER_CANCELLED',
    'DEALER_REJECTED',
    'DEALER_CANCELLED',
];

export const VALID_ORDER_STATUSES = (regionCode: string): Array<string> => {
    const { inProduction, shipped, purchaseCompleted, delivered } =
        ORDER_STATUS_CODES[regionCode];

    return [...inProduction, ...shipped, ...purchaseCompleted, ...delivered];
};

export const setLocalStorageWithSelectedVIN = (vin: string): void => {
    localStorage.setItem(VEHICLE_CARD_STORAGE_KEY, vin);
};
export const setSessionStorageWithSelectedVIN = (vin: string): void => {
    sessionStorage.setItem(VEHICLE_CARD_SESSION_STORAGE_KEY, vin);
};
export const CX740_CARD = 'CX740';
export const MAX_VEHICLES_LIMIT = 10;

export const CONNECTED_SERVICES_COUNTRY_SELECTOR: ConnectedServicesCountrySelector =
    {
        options: [
            {
                displayName: 'USA (English)',
                languageRegionCode: 'en-us',
                url: 'https://www.ford.com/myaccount/account-dashboard?connectedservices=true',
                imageUrl: 'us.svg',
            },
            {
                displayName: 'USA (Spanish)',
                languageRegionCode: 'es-us',
                url: 'https://es.ford.com/myaccount/account-dashboard?connectedservices=true',
                imageUrl: 'us.svg',
            },
            {
                displayName: 'België',
                languageRegionCode: 'nl-be',
                url: 'https://www.nl.ford.be/account-management/account-dashboard?connectedservices=true',
                imageUrl: 'be.svg',
            },
            {
                displayName: 'Belgique',
                languageRegionCode: 'fr-be',
                url: 'https://www.fr.ford.be/gestion-de-compte/account-dashboard?connectedservices=true',
                imageUrl: 'be.svg',
            },
            {
                displayName: 'Canada (English)',
                languageRegionCode: 'en-ca',
                url: 'https://www.ford.ca/myaccount/account-dashboard?connectedservices=true',
                imageUrl: 'ca.svg',
            },
            {
                displayName: 'Canada (French)',
                languageRegionCode: 'fr-ca',
                url: 'https://fr.ford.ca/myaccount/account-dashboard?connectedservices=true',
                imageUrl: 'ca.svg',
            },
            {
                displayName: 'Česká republika',
                languageRegionCode: 'cs-cz',
                url: 'https://www.ford.cz/sprava-uctu/account-dashboard?connectedservices=true',
                imageUrl: 'cz.svg',
            },
            {
                displayName: 'Danmark',
                languageRegionCode: 'da-dk',
                url: 'https://www.ford.dk/konto/account-dashboard?connectedservices=true',
                imageUrl: 'dk.svg',
            },
            {
                displayName: 'Deutschland',
                languageRegionCode: 'de-de',
                url: 'https://www.ford.de/mein-ford-account/account-dashboard?connectedservices=true',
                imageUrl: 'de.svg',
            },
            {
                displayName: 'Ελλάδα',
                languageRegionCode: 'el-gr',
                url: 'https://www.ford.gr/account-management/account-dashboard?connectedservices=true',
                imageUrl: 'gr.svg',
            },
            {
                displayName: 'Espana',
                languageRegionCode: 'es-es',
                url: 'https://www.ford.es/gestionar-cuenta/account-dashboard?connectedservices=true',
                imageUrl: 'sp.svg',
            },
            {
                displayName: 'France',
                languageRegionCode: 'fr-fr',
                url: 'https://www.ford.fr/gestion-de-compte/account-dashboard?connectedservices=true',
                imageUrl: 'fr.svg',
            },
            {
                displayName: 'Ireland',
                languageRegionCode: 'en-ie',
                url: 'https://www.ford.ie/account-management/account-dashboard?connectedservices=true',
                imageUrl: 'ie.svg',
            },
            {
                displayName: 'Italia',
                languageRegionCode: 'it-it',
                url: 'https://www.ford.it/gestione-account/account-dashboard?connectedservices=true',
                imageUrl: 'it.svg',
            },
            {
                displayName: 'Luxembourg',
                languageRegionCode: 'fr-lu',
                url: 'https://www.ford.lu/gestion-de-compte/account-dashboard?connectedservices=true',
                imageUrl: 'lu.svg',
            },
            {
                displayName: 'Magyarország',
                languageRegionCode: 'hu-hu',
                url: 'https://www.ford.hu/fiokkezeles/account-dashboard?connectedservices=true',
                imageUrl: 'hu.svg',
            },
            {
                displayName: 'Nederland',
                languageRegionCode: 'nl-nl',
                url: 'https://www.ford.nl/accountmanagement/account-dashboard?connectedservices=true',
                imageUrl: 'nl.svg',
            },
            {
                displayName: 'Norge',
                languageRegionCode: 'no-no',
                url: 'https://www.ford.no/konto-administrasjon/account-dashboard?connectedservices=true',
                imageUrl: 'no.svg',
            },
            {
                displayName: 'Österreich',
                languageRegionCode: 'de-at',
                url: 'https://www.ford.at/mein-ford-account/account-dashboard?connectedservices=true',
                imageUrl: 'at.svg',
            },
            {
                displayName: 'Polska',
                languageRegionCode: 'pl-pl',
                url: 'https://www.ford.pl/zarzadzanie-kontem/account-dashboard?connectedservices=true',
                imageUrl: 'pl.svg',
            },
            {
                displayName: 'Portugal',
                languageRegionCode: 'pt-pt',
                url: 'https://www.ford.pt/gestao-de-conta/account-dashboard?connectedservices=true',
                imageUrl: 'pt.svg',
            },
            {
                displayName: 'România',
                languageRegionCode: 'ro-ro',
                url: 'https://www.ford.ro/gestionare-cont/account-dashboard?connectedservices=true',
                imageUrl: 'ro.svg',
            },
            {
                displayName: 'Schweiz',
                languageRegionCode: 'de-ch',
                url: 'https://www.de.ford.ch/mein-ford-account/account-dashboard?connectedservices=true',
                imageUrl: 'ch.svg',
            },
            {
                displayName: 'Suisse',
                languageRegionCode: 'fr-ch',
                url: 'https://www.fr.ford.ch/gestion-de-compte/account-dashboard?connectedservices=true',
                imageUrl: 'ch.svg',
            },
            {
                displayName: 'Svizzera',
                languageRegionCode: 'it-ch',
                url: 'https://www.it.ford.ch/gestione-account/account-dashboard?connectedservices=true',
                imageUrl: 'ch.svg',
            },
            {
                displayName: 'Suomi',
                languageRegionCode: 'fi-fi',
                url: 'https://www.ford.fi/tilin-hallinta/account-dashboard?connectedservices=true',
                imageUrl: 'fi.svg',
            },
            {
                displayName: 'Sverige',
                languageRegionCode: 'sv-se',
                url: 'https://www.seaccount.ford.com/account-dashboard?connectedservices=true',
                imageUrl: 'se.svg',
            },
            {
                displayName: 'United Kingdom',
                languageRegionCode: 'en-gb',
                url: 'https://www.ford.co.uk/account-management/account-dashboard?connectedservices=true',
                imageUrl: 'gb.svg',
            },
        ],
    };
export const NON_GA_COUNTRIES: CountriesByLocale = {
    'en-bg': {
        languageName: 'English',
        twoLetterCountryCode: 'bg',
        threeLetterCountryCode: 'bgr',
    },
    'en-hr': {
        languageName: 'English',
        twoLetterCountryCode: 'hr',
        threeLetterCountryCode: 'hrv',
    },
    'en-ee': {
        languageName: 'English',
        twoLetterCountryCode: 'ee',
        threeLetterCountryCode: 'est',
    },
    'en-is': {
        languageName: 'English',
        twoLetterCountryCode: 'is',
        threeLetterCountryCode: 'isl',
    },
    'da-is': {
        languageName: 'Danish',
        twoLetterCountryCode: 'is',
        threeLetterCountryCode: 'isl',
    },
    'en-lv': {
        languageName: 'English',
        twoLetterCountryCode: 'lv',
        threeLetterCountryCode: 'lva',
    },
    'de-li': {
        languageName: 'German',
        twoLetterCountryCode: 'li',
        threeLetterCountryCode: 'lie',
    },
    'en-lt': {
        languageName: 'English',
        twoLetterCountryCode: 'lt',
        threeLetterCountryCode: 'ltu',
    },
    'en-sk': {
        languageName: 'English',
        twoLetterCountryCode: 'sk',
        threeLetterCountryCode: 'svk',
    },
    'sk-sk': {
        languageName: 'Slovak',
        twoLetterCountryCode: 'sk',
        threeLetterCountryCode: 'svk',
    },
    'en-si': {
        languageName: 'English',
        twoLetterCountryCode: 'si',
        threeLetterCountryCode: 'svn',
    },
    'sl-si': {
        languageName: 'Slovenian',
        twoLetterCountryCode: 'si',
        threeLetterCountryCode: 'svn',
    },
    'en-cy': {
        languageName: 'English',
        twoLetterCountryCode: 'cy',
        threeLetterCountryCode: 'cyp',
    },
    'de-lu': {
        languageName: 'German',
        twoLetterCountryCode: 'lu',
        threeLetterCountryCode: 'lux',
    },
    'it-mt': {
        languageName: 'Italian',
        twoLetterCountryCode: 'mt',
        threeLetterCountryCode: 'mlt',
    },
    'en-mt': {
        languageName: 'English',
        twoLetterCountryCode: 'mt',
        threeLetterCountryCode: 'mlt',
    },
    'fr-mc': {
        languageName: 'French',
        twoLetterCountryCode: 'mc',
        threeLetterCountryCode: 'mco',
    },
    'fr-li': {
        languageName: 'French',
        twoLetterCountryCode: 'li',
        threeLetterCountryCode: 'lie',
    },
    'it-li': {
        languageName: 'Italian',
        twoLetterCountryCode: 'li',
        threeLetterCountryCode: 'lie',
    },
    'tr-tr': {
        languageName: 'Turkish',
        twoLetterCountryCode: 'tr',
        threeLetterCountryCode: 'tur',
    },
    'en-tr': {
        languageName: 'English',
        twoLetterCountryCode: 'tr',
        threeLetterCountryCode: 'tur',
    },
    'en-bw': {
        languageName: 'English',
        twoLetterCountryCode: 'bw',
        threeLetterCountryCode: 'bwa',
    },
    'en-ls': {
        languageName: 'English',
        twoLetterCountryCode: 'ls',
        threeLetterCountryCode: 'lso',
    },
    'en-sz': {
        languageName: 'English',
        twoLetterCountryCode: 'sz',
        threeLetterCountryCode: 'swz',
    },
    'en-na': {
        languageName: 'English',
        twoLetterCountryCode: 'na',
        threeLetterCountryCode: 'nam',
    },
    'en-in': {
        languageName: 'English',
        twoLetterCountryCode: 'in',
        threeLetterCountryCode: 'ind',
    },
    'en-vn': {
        languageName: 'English',
        twoLetterCountryCode: 'vn',
        threeLetterCountryCode: 'vnm',
    },
};

export const FORDPASS_AUTHORIZED_DEEPLINK = 'fordapp://userauthorized';
export const LINCOLNWAY_AUTHORIZED_DEEPLINK = 'lincolnapp://userauthorized';
export const FORDPASS_PREFERENCES_DEEPLINK = 'fordapp://preferences';

export const RANDOM_EU_VINS: Array<string> = [
    'WF0ZXXTTGZLY77413',
    'WF0ZXXTTGZLY77406',
    'WF0NXXGCHNLE16235',
    'WF0JXXWPCJLY77387',
    'WF0FXXWPMFLY76276',
    'WF0JXXGAHJLY51628',
    'WF0EXXTTREKU80643',
    'WF0KXXTTRKLK59311',
    'WF0JXXWPCJLY81324',
    'WF0PXXGCHPLY64370',
    'WF01XXERK1LL71335',
    'WF0RXXWPGRLL49432',
];

export const EU_VPHD_FAKE_VIN = 'WF0FXXWPMFLB59456';

export const generateRandomEuVin = (): string => {
    return RANDOM_EU_VINS[
        Math.floor(RandomNumberUtil.getRandomNumber() * RANDOM_EU_VINS.length)
    ];
};

export const DIG_PROD_URL = 'https://build.ford.com/dig/';
export const DIG_QA_URL = 'https://wwwqa.build.ford.com/dig/';

export const NUMBER_OF_TRACKER_STEPS = 6;

export const CVOT_IMAGE_URL_BASE = 'https://shop.ford.com/cmslibs';

export const modelPartClassNameList: string[] = [
    'Engine',
    'Transmissions',
    'Drive',
    'ModelGroup',
];
export const exteriorPartClassNameList: string[] = [
    'Exterior Options',
    'Paint Type',
    'Mirror Options',
    'BodyStyle',
    'Paint Applications',
    'Running Boards',
    'Accessory Running Boards',
    'Roof Color',
    'Rear Axle Ratios',
    'Wheel Type',
    'Tire Type',
    'Accessory Wheels',
    'Racing Stripe',
    'Hood Side Stripes',
];
export const interiorPartClassNameList: string[] = [
    'Interior Options',
    'Interior Trim Colour',
    'Interior Packages',
    'Radio Type',
    'Audio Upgrade',
    'Seat Type',
    '2nd Row Seats',
    '3rd Row Seats',
];
export const accessoriesPartClassNameList: string[] = [
    'Appearance',
    'Bed Products',
    'Electronics',
    'Exterior Accessories',
    'Interior Accessories',
    'Hood and Side Stripes',
    'Options',
];
export const packagesPartClassNameList: string[] = [
    'Pep Code',
    'Special Package',
];

export enum LinkTargetValues {
    SELF = '_self',
    BLANK = '_blank',
    PARENT = '_parent',
    TOP = '_top',
}

export const NATIONAL_CURRENCY = {
    us: 'USD',
    ca: 'CAD',
};

export const DAY_MONTH_YEAR_REGIONS = ['fr-ca'];

export const NVC_PAST_ORDER_STATUSES = [
    'cancelled',
    'completed',
    'return completed',
    'picked up',
];

export const INSTALL_LINES_ID = '1N5TA11';
