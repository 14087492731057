import React, { Dispatch, SetStateAction } from 'react';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { CacheService } from '@services/cache-service/cache-service';
import { SecurityContent } from '@sections/sign-in-and-security/hook/use-security-content';
import MFAService, { MFAOptions } from '@services/mfa-service/mfa-service';
import { LinkButton } from '@common/modal/link-button/link-button';
import './sign-in-and-security.scss';
import SignInAndSecurityErrorContent from '@sections/sign-in-and-security/sign-in-and-security-error-content';
import { useAnalytics } from '@/hooks/use-analytics';
import Toggle from '@common/toggle/toggle';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { NotificationType } from '@contexts/notificationContext';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';

interface SignInAndSecurityProps {
    userId: string;
    mfaOptions: MFAOptions[];
    showMFASection: boolean;
    setMFAOptions: Dispatch<SetStateAction<MFAOptions[]>>;
    userRequiresMFA: boolean;
    setUserRequiresMFA: Dispatch<SetStateAction<boolean>>;
    mfaService: MFAService;
    securityContent: SecurityContent;
    fromWebview: boolean;
    errorNotificationMessage: string;
    setErrorNotification: (message: string) => void;
    clearErrorNotification: () => void;
    showErrorPage: boolean;
    setShowErrorPage: Dispatch<SetStateAction<boolean>>;
    scrollUtil: ScrollUtil;
    isLincoln?: boolean;
}

const SignInAndSecurity = (props: SignInAndSecurityProps) => {
    const {
        securityContent,
        mfaOptions,
        showMFASection,
        setMFAOptions,
        userRequiresMFA,
        setUserRequiresMFA,
        userId,
        fromWebview,
        mfaService,
        errorNotificationMessage,
        setErrorNotification,
        clearErrorNotification,
        showErrorPage,
        setShowErrorPage,
        scrollUtil,
        isLincoln,
    } = props;

    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();
    const [fireAnalytics] = useAnalytics();
    const { setContext, resetContext } = useModalContext();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const mfaSectionIsVisible = Boolean(
        showMFASection &&
            securityContent.MFASectionHeader &&
            (mfaOptions?.length || securityContent.MFAToggleHeader)
    );

    const userIdTypeIsEmail: boolean = userId.includes('@');
    const mfaEmail: MFAOptions = mfaOptions?.find(
        (option) => option.type === 'email'
    );
    const mfaApp: MFAOptions = mfaOptions?.find(
        (option) => option.type === 'totp'
    );
    const mfaSms: MFAOptions = mfaOptions?.find(
        (option) => option.type === 'phone'
    );
    const hasEmailAuthentication = !!mfaEmail;
    const hasAppAuthentication = !!mfaApp;
    const hasSmsAuthentication = !!mfaSms;
    const userHasOnlyOneMFAOption: boolean = mfaOptions?.length === 1;

    const maskedEmailAddress = (userId: string): string => {
        const userIdSplit: Array<string> = userId.split('@');
        const prefix: string = userIdSplit[0];
        const firstChar: string = prefix[0];
        const lastChar: string = prefix[prefix.length - 1];
        const domain: string = userIdSplit[1];

        return `${firstChar}***${lastChar}@${domain}`;
    };

    const maskedPhoneNumber = (phoneNumber: string): string => {
        const lastFour = phoneNumber.substring(phoneNumber.length - 4);
        return `******${lastFour}`;
    };

    const maskedUserId = (userId: string): string => {
        return userIdTypeIsEmail
            ? maskedEmailAddress(userId)
            : maskedPhoneNumber(userId);
    };

    const deleteMFAOption = async (optionType: string) => {
        clearErrorNotification();
        if (userHasOnlyOneMFAOption) {
            setShowErrorPage(true);
            return;
        }

        const response = await mfaService.deleteMFAOption(optionType);

        if (!response) {
            setErrorNotification(
                securityContent.mfaDeleteOptionErrorNotificationMessage
            );
            scrollUtil.scrollPageToTop();
            return;
        }

        if (response.status === 204) {
            const updatedMFAOptions = mfaOptions.filter(
                (option) => option.type !== optionType
            );

            setMFAOptions(updatedMFAOptions);
        }
    };

    const configureWebviewLogout = () => {
        if (fromWebview) {
            authenticationService.updateStateWithDeepLink();
        }
    };

    const handleToggleChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setUserRequiresMFA(e.target.checked);
        if (e.target.checked) {
            const mfaToggle = 'on';
            fireAnalytics('signInCredentialsMfaToggleOnclickEvent', '', {
                mfaToggle,
            });
            setContext(mfaEnableModalProps);
        } else {
            const mfaToggle = 'off';
            fireAnalytics('signInCredentialsMfaToggleOnclickEvent', '', {
                mfaToggle,
            });
            setContext(mfaDisableModalProps);
        }
    };

    const handleModalClose = () => {
        resetContext();
        setUserRequiresMFA((prevState) => !prevState);
    };

    const enableMFA = async () => {
        const response = await updateUserLevelMFAFlag(true);
        if (response) {
            authenticationService.updateLogoutURL(window.location.href);
            authenticationService.logout();
        }
    };

    const disableMFA = async () => {
        await updateUserLevelMFAFlag(false);
    };

    const updateUserLevelMFAFlag = async (flag: boolean) => {
        clearErrorNotification();
        setIsLoading(true);
        const response = await mfaService.updateUserLevelMfaFlag(flag);
        setIsLoading(false);
        if (!response) {
            setUserRequiresMFA((prevState) => !prevState);
            setErrorNotification(
                securityContent.mfaUpdateUserLevelFlagErrorNotificationMessage
            );
            scrollUtil.scrollPageToTop();
        }
        return response;
    };

    const mfaEnableModalProps: ModalContextProps = {
        modalType: {
            name: 'mfa-enable-modal',
            primaryButtonLabel:
                securityContent.MFAEnableModalConfirmButtonLabelText,
            primaryButtonAriaLabel:
                securityContent.MFAEnableModalConfirmButtonAriaLabel,
            secondaryButtonLabel:
                securityContent.MFAEnableModalCancelButtonLabelText,
            secondaryButtonAriaLabel:
                securityContent.MFAEnableModalCancelButtonAriaLabel,
            closeButtonAriaLabel:
                securityContent.MFAEnableModalCloseButtonAriaLabel,
            onPrimaryButtonClick: async () => {
                const mfaModal = 'enable confirm';
                fireAnalytics('signInCredentialsMfaModalOnclickEvent', '', {
                    mfaModal,
                });
                resetContext();
                await enableMFA();
            },
            onSecondaryButtonClick: () => {
                const mfaModal = 'enable cancel';
                fireAnalytics('signInCredentialsMfaModalOnclickEvent', '', {
                    mfaModal,
                });
                handleModalClose();
            },
            onAfterClose: () => {
                const mfaModal = 'enable cancel';
                fireAnalytics('signInCredentialsMfaModalOnclickEvent', '', {
                    mfaModal,
                });
                handleModalClose();
            },
            children: (
                <>
                    <h2
                        className={`fmc-type--heading6 ${
                            isLincoln
                                ? 'fds-color__text--primary fds-align--center'
                                : ''
                        }`}
                    >
                        {securityContent?.MFAEnableModalHeaderText}
                    </h2>
                    <p
                        className={`fmc-type--body2 ${
                            isLincoln
                                ? 'fds-color__text--gray3 fds-align--center'
                                : ''
                        }`}
                    >
                        {securityContent.MFAEnableModalBodyText}
                    </p>
                </>
            ),
        },
    };

    const mfaDisableModalProps: ModalContextProps = {
        modalType: {
            name: 'mfa-disable-modal',
            primaryButtonLabel:
                securityContent.MFADisableModalConfirmButtonLabelText,
            primaryButtonAriaLabel:
                securityContent.MFADisableModalConfirmButtonAriaLabel,
            secondaryButtonLabel:
                securityContent.MFADisableModalCancelButtonLabelText,
            secondaryButtonAriaLabel:
                securityContent.MFADisableModalCancelButtonAriaLabel,
            closeButtonAriaLabel:
                securityContent.MFADisableModalCloseButtonAriaLabel,
            onPrimaryButtonClick: async () => {
                const mfaModal = 'disable confirm';
                fireAnalytics('signInCredentialsMfaModalOnclickEvent', '', {
                    mfaModal,
                });
                resetContext();
                await disableMFA();
            },
            onSecondaryButtonClick: () => {
                const mfaModal = 'disable cancel';
                fireAnalytics('signInCredentialsMfaModalOnclickEvent', '', {
                    mfaModal,
                });
                handleModalClose();
            },
            onAfterClose: () => {
                const mfaModal = 'disable cancel';
                fireAnalytics('signInCredentialsMfaModalOnclickEvent', '', {
                    mfaModal,
                });
                handleModalClose();
            },
            children: (
                <>
                    <h2
                        className={`fmc-type--heading6 ${
                            isLincoln
                                ? 'fds-color__text--primary fds-align--center'
                                : ''
                        }`}
                    >
                        {securityContent?.MFADisableModalHeaderText}
                    </h2>
                    <p
                        className={`fmc-type--body2 ${
                            isLincoln
                                ? 'fds-color__text--gray3 fds-align--center'
                                : ''
                        }`}
                    >
                        {securityContent.MFADisableModalBodyText}
                    </p>
                </>
            ),
        },
    };

    return userId ? (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            <img
                alt=""
                className={`brand-logo ${fromWebview ? 'from-webview' : ''}`}
                src={
                    process.env.REACT_APP_AEM_BASE_URL +
                    securityContent.brandLogo
                }
            />

            <div className="sign-in-credentials__content">
                {!showErrorPage ? (
                    <>
                        {errorNotificationMessage && (
                            <div
                                className={`sign-in-credentials__notification-container`}
                                data-testid={`sign-in-credentials__notification-container`}
                            >
                                <Notification
                                    status={NotificationType.Error}
                                    mainCopy={errorNotificationMessage}
                                    hideBorder={true}
                                    hideAfterTimeout={true}
                                    onHideNotification={() => {
                                        clearErrorNotification();
                                    }}
                                />
                            </div>
                        )}
                        <h1 className="sign-in-credentials__section-header">
                            {securityContent.signInCredentialsSectionHeader}
                        </h1>
                        <section className="sign-in-credentials__field-row">
                            <div className="sign-in-credentials__field-label">
                                <p className="sign-in-credentials__label">
                                    {securityContent.emailSectionLabel}
                                </p>
                                <p className="sign-in-credentials__value">
                                    {maskedUserId(userId)}{' '}
                                </p>
                            </div>

                            <LinkButton
                                label={securityContent.editButtonText}
                                ariaLabel={`${securityContent.editButtonText} ${securityContent.emailSectionLabel}`}
                                dataTestId="email-edit-button"
                                class="edit-link"
                                onClick={() => {
                                    fireAnalytics(
                                        'signInCredentialsEditOnclickEvent'
                                    );
                                    cacheService.evictProfileServiceCache();
                                    configureWebviewLogout();
                                    authenticationService.changeUsername();
                                }}
                                id="email-edit-button"
                                ariaHidden={false}
                                tabIndex={0}
                            />
                        </section>
                        <hr />
                        <section className="sign-in-credentials__field-row">
                            <div className="sign-in-credentials__field-label">
                                <p className="sign-in-credentials__label">
                                    {securityContent.passwordSectionLabel}
                                </p>
                                <p className="sign-in-credentials__value">
                                    ********
                                </p>
                            </div>

                            <LinkButton
                                label={securityContent.editButtonText}
                                ariaLabel={`${securityContent.editButtonText} ${securityContent.passwordSectionLabel}`}
                                dataTestId="password-edit-button"
                                class="edit-link"
                                onClick={() => {
                                    fireAnalytics(
                                        'signInCredentialsEditOnclickEvent'
                                    );
                                    cacheService.evictProfileServiceCache();
                                    configureWebviewLogout();
                                    authenticationService.changePassword();
                                }}
                                id="password-edit-button"
                                ariaHidden={false}
                                tabIndex={0}
                            />
                        </section>
                        <hr />
                        {mfaSectionIsVisible && (
                            <div
                                className="mfa-section"
                                data-testid="mfa-section"
                            >
                                <h1 className="sign-in-credentials__section-header">
                                    {securityContent.MFASectionHeader}
                                </h1>
                                <p className="sign-in-credentials__mfa--description">
                                    {securityContent.MFADescription}
                                </p>

                                {securityContent.MFAToggleHeader && (
                                    <div className="mfa-toggle-section">
                                        <p className="mfa-toggle-section__header">
                                            {securityContent.MFAToggleHeader}
                                        </p>
                                        <div className="mfa-toggle-section__switch-container">
                                            <Toggle
                                                isToggleChecked={
                                                    userRequiresMFA
                                                }
                                                handleChange={
                                                    handleToggleChange
                                                }
                                                isLincoln={isLincoln}
                                            />
                                            <p
                                                className="mfa-toggle-section__switch-label"
                                                data-testid="mfa-toggle-section__switch-label"
                                            >
                                                {userRequiresMFA
                                                    ? securityContent.MFAToggleOnLabelText
                                                    : securityContent.MFAToggleOffLabelText}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {!!mfaOptions?.length && (
                                    <div
                                        className="mfa-section__options"
                                        data-testid="mfa-section__options"
                                    >
                                        <section className="sign-in-credentials__field-row">
                                            <div className="sign-in-credentials__field-label">
                                                <p className="sign-in-credentials__label">
                                                    {
                                                        securityContent.MFAEmailAuthenticationSectionLabel
                                                    }
                                                </p>
                                                <p className="sign-in-credentials__value">
                                                    {hasEmailAuthentication && (
                                                        <span>
                                                            {maskedUserId(
                                                                mfaEmail.value
                                                            )}{' '}
                                                        </span>
                                                    )}
                                                    {!hasEmailAuthentication && (
                                                        <span>
                                                            {
                                                                securityContent.MFAEmailAuthenticationDescriptionText
                                                            }
                                                        </span>
                                                    )}
                                                </p>
                                            </div>

                                            <div className="cta-button-box">
                                                {!hasEmailAuthentication && (
                                                    <LinkButton
                                                        label={
                                                            securityContent.addButtonText
                                                        }
                                                        ariaLabel={`${securityContent.addButtonText} ${securityContent.MFAEmailAuthenticationSectionLabel}`}
                                                        dataTestId="email-authentication-add-button"
                                                        class="edit-link"
                                                        onClick={() => {
                                                            fireAnalytics(
                                                                'signInCredentialsAddOnclickEvent'
                                                            );
                                                            cacheService.evictProfileServiceCache();
                                                            authenticationService.addMFAEmailAuthentication();
                                                        }}
                                                        id="email-authentication-add-button"
                                                        ariaHidden={false}
                                                        tabIndex={0}
                                                    />
                                                )}
                                                {hasEmailAuthentication && (
                                                    <>
                                                        <LinkButton
                                                            label={
                                                                securityContent.editButtonText
                                                            }
                                                            ariaLabel={`${securityContent.editButtonText} ${securityContent.MFAEmailAuthenticationSectionLabel}`}
                                                            dataTestId="email-authentication-edit-button"
                                                            class="edit-link"
                                                            onClick={() => {
                                                                fireAnalytics(
                                                                    'signInCredentialsEditOnclickEvent'
                                                                );
                                                                cacheService.evictProfileServiceCache();

                                                                if (
                                                                    userIdTypeIsEmail
                                                                ) {
                                                                    authenticationService.changeUsername();
                                                                } else {
                                                                    authenticationService.changeMFAEmailAuthentication();
                                                                }
                                                            }}
                                                            id="email-authentication-edit-button"
                                                            ariaHidden={false}
                                                            tabIndex={0}
                                                        />

                                                        <LinkButton
                                                            label={
                                                                securityContent.removeButtonText
                                                            }
                                                            ariaLabel={`${securityContent.removeButtonText} ${securityContent.MFAEmailAuthenticationSectionLabel}`}
                                                            dataTestId="email-authentication-remove-button"
                                                            class="edit-link"
                                                            onClick={() => {
                                                                fireAnalytics(
                                                                    'signInCredentialsRemoveOnclickEvent'
                                                                );
                                                                deleteMFAOption(
                                                                    mfaEmail.type
                                                                );
                                                            }}
                                                            id="email-authentication-remove-button"
                                                            ariaHidden={false}
                                                            tabIndex={0}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </section>

                                        <hr />
                                        <section className="sign-in-credentials__field-row">
                                            <div className="sign-in-credentials__field-label">
                                                <p className="sign-in-credentials__label">
                                                    {
                                                        securityContent.MFAAppAuthenticationSectionLabel
                                                    }
                                                </p>
                                                <p className="sign-in-credentials__value">
                                                    {
                                                        securityContent.MFAAppAuthenticationDescriptionText
                                                    }
                                                </p>
                                            </div>
                                            {!hasAppAuthentication && (
                                                <LinkButton
                                                    label={
                                                        securityContent.addButtonText
                                                    }
                                                    ariaLabel={`${securityContent.addButtonText} ${securityContent.MFAAppAuthenticationSectionLabel}`}
                                                    dataTestId="app-authentication-add-button"
                                                    class="edit-link"
                                                    onClick={() => {
                                                        fireAnalytics(
                                                            'signInCredentialsAddOnclickEvent'
                                                        );
                                                        cacheService.evictProfileServiceCache();
                                                        authenticationService.addMFAAppAuthentication();
                                                    }}
                                                    id="app-authentication-add-button"
                                                    ariaHidden={false}
                                                    tabIndex={0}
                                                />
                                            )}

                                            {hasAppAuthentication && (
                                                <div className="cta-button-box">
                                                    <LinkButton
                                                        label={
                                                            securityContent.editButtonText
                                                        }
                                                        ariaLabel={`${securityContent.editButtonText} ${securityContent.MFAAppAuthenticationSectionLabel}`}
                                                        dataTestId="app-authentication-edit-button"
                                                        class="edit-link"
                                                        onClick={() => {
                                                            fireAnalytics(
                                                                'signInCredentialsEditOnclickEvent'
                                                            );
                                                            cacheService.evictProfileServiceCache();
                                                            authenticationService.changeMFAAppAuthentication();
                                                        }}
                                                        id="app-authentication-edit-button"
                                                        ariaHidden={false}
                                                        tabIndex={0}
                                                    />
                                                    <LinkButton
                                                        label={
                                                            securityContent.removeButtonText
                                                        }
                                                        ariaLabel={`${securityContent.removeButtonText} ${securityContent.MFAAppAuthenticationSectionLabel}`}
                                                        dataTestId="app-authentication-remove-button"
                                                        class="edit-link"
                                                        onClick={() => {
                                                            fireAnalytics(
                                                                'signInCredentialsRemoveOnclickEvent'
                                                            );
                                                            deleteMFAOption(
                                                                mfaApp.type
                                                            );
                                                        }}
                                                        id="app-authentication-remove-button"
                                                        ariaHidden={false}
                                                        tabIndex={0}
                                                    />
                                                </div>
                                            )}
                                        </section>
                                        <hr />
                                        <section className="sign-in-credentials__field-row">
                                            <div className="sign-in-credentials__field-label">
                                                <p className="sign-in-credentials__label">
                                                    {
                                                        securityContent.MFASmsAuthenticationSectionLabel
                                                    }
                                                </p>
                                                <p className="sign-in-credentials__value">
                                                    {!hasSmsAuthentication && (
                                                        <span>
                                                            {
                                                                securityContent.MFASmsAuthenticationDescriptionText
                                                            }
                                                        </span>
                                                    )}
                                                    {hasSmsAuthentication && (
                                                        <span>
                                                            {maskedPhoneNumber(
                                                                mfaSms.value
                                                            )}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                            {!hasSmsAuthentication && (
                                                <LinkButton
                                                    label={
                                                        securityContent.addButtonText
                                                    }
                                                    ariaLabel={`${securityContent.addButtonText} ${securityContent.MFASmsAuthenticationSectionLabel}`}
                                                    dataTestId="sms-authentication-add-button"
                                                    class="edit-link"
                                                    onClick={() => {
                                                        fireAnalytics(
                                                            'signInCredentialsAddOnclickEvent'
                                                        );
                                                        cacheService.evictProfileServiceCache();
                                                        authenticationService.addMFASmsAuthentication();
                                                    }}
                                                    id="sms-authentication-add-button"
                                                    ariaHidden={false}
                                                    tabIndex={0}
                                                />
                                            )}

                                            {hasSmsAuthentication && (
                                                <div className="cta-button-box">
                                                    <LinkButton
                                                        label={
                                                            securityContent.editButtonText
                                                        }
                                                        ariaLabel={`${securityContent.editButtonText} ${securityContent.MFASmsAuthenticationSectionLabel}`}
                                                        dataTestId="sms-authentication-edit-button"
                                                        class="edit-link"
                                                        onClick={() => {
                                                            fireAnalytics(
                                                                'signInCredentialsEditOnclickEvent'
                                                            );
                                                            cacheService.evictProfileServiceCache();

                                                            if (
                                                                !userIdTypeIsEmail
                                                            ) {
                                                                authenticationService.changeUsername();
                                                            } else {
                                                                authenticationService.changeMFASmsAuthentication();
                                                            }
                                                        }}
                                                        id="sms-authentication-edit-button"
                                                        ariaHidden={false}
                                                        tabIndex={0}
                                                    />
                                                    <LinkButton
                                                        label={
                                                            securityContent.removeButtonText
                                                        }
                                                        ariaLabel={`${securityContent.removeButtonText} ${securityContent.MFASmsAuthenticationSectionLabel}`}
                                                        dataTestId="sms-authentication-remove-button"
                                                        class="edit-link"
                                                        onClick={() => {
                                                            fireAnalytics(
                                                                'signInCredentialsRemoveOnclickEvent'
                                                            );
                                                            deleteMFAOption(
                                                                mfaSms.type
                                                            );
                                                        }}
                                                        id="sms-authentication-remove-button"
                                                        ariaHidden={false}
                                                        tabIndex={0}
                                                    />
                                                </div>
                                            )}
                                        </section>
                                        <hr />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <SignInAndSecurityErrorContent
                        securityContent={securityContent}
                        setShowErrorPage={setShowErrorPage}
                    />
                )}
            </div>
        </>
    ) : null;
};

export default SignInAndSecurity;
