import React from 'react';
import { PreferredDealerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-content';

interface ReplaceDealerModalContentProps {
    preferredDealerContent: PreferredDealerContent;
    dealerName: string;
}

const ReplaceDealerModalContent = (props: ReplaceDealerModalContentProps) => {
    const updatedBodyTextWithDealerName =
        props.preferredDealerContent?.dynamicReplaceDealerModalBodyText.replace(
            '[dealer-name]',
            props.dealerName
        );
    return (
        <>
            <h2 data-testid="replace-modal-header-text">
                {
                    props.preferredDealerContent
                        ?.dynamicReplaceDealerModalHeaderText
                }
            </h2>

            <p data-testid="replace-modal-body-text">
                <span
                    className="notification-sub-copy"
                    data-testid="notification-sub-copy"
                    dangerouslySetInnerHTML={{
                        __html: updatedBodyTextWithDealerName,
                    }}
                ></span>
            </p>
        </>
    );
};

export default ReplaceDealerModalContent;
