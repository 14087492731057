import { useContent } from '@hooks/use-server-content';

export interface PreferredDealerContent {
    returnButtonText: string;
    returnButtonAriaLabel: string;
    headerText: string;
    vinLabelText: string;
    searchByText: string;
    locationText: string;
    dealerNameText: string;
    reviewsText: string;
    milesAwayText: string;
    openUntilText: string;
    myDealerButtonLabelText: string;
    myDealerButtonAriaLabel: string;
    makeMyDealerButtonLabelText: string;
    makeMyDealerButtonAriaLabel: string;
    viewMoreButtonLabelText: string;
    viewMoreButtonAriaLabel: string;
    directionsText: string;
    distanceText: string;
    dealerWebsiteButtonLabelText: string;
    dealerWebsiteButtonAriaLabel: string;
    viewMapButtonLabelText: string;
    viewMapButtonAriaLabel: string;
    mapAriaLabel: string;
    searchFieldByLocationPlaceholderText: string;
    searchFieldByDealerNamePlaceholderText: string;
    replaceMyDealerModalHeaderText: string;
    replaceMyDealerModalBodyText: string;
    replaceMyDealerModalConfirmButtonLabelText: string;
    replaceMyDealerModalConfirmButtonAriaLabel: string;
    replaceMyDealerModalCancelButtonLabelText: string;
    replaceMyDealerModalCancelButtonAriaLabel: string;
    replaceMyDealerModalCloseButtonAriaLabel: string;
    errorMessageInvalidPostalCode: string;
    errorMessageNoResults: string;
    errorMessageSearchUnavailable: string;
    errorMessageInvalidCityState: string;
    errorMessageInvalidUnknownLocationSearch: string;
    searchSubmitButtonLabelText: string;
    searchSubmitButtonAriaLabel: string;
    dynamicReplaceDealerModalHeaderText: string;
    dynamicReplaceDealerModalBodyText: string;
}

export const usePreferredDealerContent = (): PreferredDealerContent | null => {
    const [content, getValueByName] = useContent('common', 'preferred-dealer');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    if (contentFragment) {
        if (contentFragment.hide) {
            return null;
        }
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as PreferredDealerContent;
};
