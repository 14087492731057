import React from 'react';
import './preferred-dealer-consent-modal.scss';

interface PreferredDealerConsentProps {
    preferredDealerConsentContent: {
        vehicleText: string;
        modalBodyText: string;
        modalHeaderText: string;
    };
    vinWithoutConsent?: vehicle;
}

interface vehicle {
    vin: string;
    model: string;
    year: number;
}

const PreferredDealerConsentContent = (props: PreferredDealerConsentProps) => {
    return (
        <>
            {props.preferredDealerConsentContent && (
                <div>
                    <h3
                        className="preferred-dealer-consent-message"
                        data-testid="preferred-dealer-consent-message"
                    >
                        {props.preferredDealerConsentContent.modalHeaderText}
                    </h3>
                    <div
                        className="notification-sub-copy"
                        data-testid="notification-sub-copy"
                        dangerouslySetInnerHTML={{
                            __html: props.preferredDealerConsentContent
                                .modalBodyText,
                        }}
                    ></div>
                    {props.vinWithoutConsent && (
                        <div className="vehicle-section">
                            <p>
                                {
                                    props.preferredDealerConsentContent
                                        .vehicleText
                                }
                            </p>
                            <div className="vehicle-details-section">
                                <p>
                                    <strong>
                                        {`${props.vinWithoutConsent.year} ${props.vinWithoutConsent.model}`}
                                    </strong>
                                </p>
                                <p key={props.vinWithoutConsent.vin}>
                                    {props.vinWithoutConsent.vin}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default PreferredDealerConsentContent;
