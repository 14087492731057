import HttpService from '@services/http-service/http-service';
import httpAdapter from 'axios/lib/adapters/http';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import { HttpsProxyAgent } from 'https-proxy-agent';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import axios from 'axios';
import {
    PreferredDealerConsentResponse,
    PrivacyPreferencesRequestData,
} from '@/models/preferred-dealer-consent';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class PreferredDealerConsentService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private url = DSL_BASE_URL + DSL_API_PATH.PREFERRED_DEALER_CONSENT;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public getPreferredDealerConsentResults = async (
        countryCode: string
    ): Promise<PreferredDealerConsentResponse | null> => {
        return this.httpService
            .get(`${this.url}?countryCode=${countryCode}`, false, {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            })
            .then((response) => {
                if (response.status === 200 && response.data.error === null) {
                    return response.data;
                }

                return null;
            })
            .catch((e) => {
                return e.response.data;
            });
    };

    public updatePreferredDealerConsent = async (
        privacyPreferencesRequestData: PrivacyPreferencesRequestData
    ) => {
        const dslUrl = this.appConfig.getAppConfiguration().dslUrl
            ? this.appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.PREFERRED_DEALER_CONSENT;

        return axios
            .post<any>(url, privacyPreferencesRequestData, {
                headers: {
                    ...HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((e) => e.response.data);
    };
}
